.mat-calendar-body-selected {
  border-color: $color-brand-green;
  color: $color-black;
  background-color: $color-brand-green-bright;
  font-weight: $font-weight-medium;
}

.mat-calendar-body-begin-range:not(.mat-calendar-body-end-range),
.mat-calendar-body-end-range:not(.mat-calendar-body-begin-range),
.mat-calendar-cell-semi-selected {
  background-color: rgba($color-active, .1);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: $color-brand-green-lighter;
  color: $color-black-lighter;
  background-color: $color-white;
}

.mat-calendar-body-in-range::before {
  background-color: $color-black-brighter;
}

.mat-calendar-arrow {
  display: inline-block;
  height: 14px !important;
  width: 20px !important;
  margin-left: 10px;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: $font-weight-regular;
  color: $color-warm;
  font-family: $font-family-awesome;
  border: none !important;

  &::before {
    content: "\f078";
  }

  &.mat-calendar-invert {
    &::before {
      content: "\f077";
    }
  }
}

.mat-calendar-body-label {
  opacity: 0;
}

.mat-button-focus-overlay {
  background-color: transparent;
}

:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-semi-selected,
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-semi-selected,
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-semi-selected {
  color: $color-dark;
}

.mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button, .mat-datepicker-toggle {
  color: $color-black-lighter;
}

.mat-datepicker-content {
  button:hover, button:focus-visible, .btn:hover, .btn:focus-visible {
    color: $color-brand-green;
  }
}

.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  border-color: $color-brand-green;
  background-color: $color-white;
  font-weight: $font-weight-medium;
}

.mat-calendar-cell-over > .mat-calendar-body-cell-content {
  border-color: $color-brand-green;
}

.mat-datepicker-content {
  border-radius: $border-radius;
  box-shadow: $shadow-box;
}


.mat-calendar .mat-calendar-body-cell-content {
  border-radius: $border-radius;
}
