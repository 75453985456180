/* You can add global styles to this file, and also import other style files */
@import "styles/styles";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

* {
  box-sizing: border-box;
}

div.main-container {
  height: calc(100vh - 50px);
}

div.main-container div.main {
  padding: 20px 30px;
  height: calc(100vh - 70px);
}

div.main-container div.main h3.title,
div.main-container div.main h2.title {
  padding-bottom: 10px;
}

a.like-btn {
  display: block;
  padding: 10px;
  width: 100%;
  text-align: center;
  text-decoration: none;
}

div.x2-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

div.x2-container div.x2-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

div.x2-container div.x2-container h5.title {
  padding-bottom: 15px;
}

div.x2-container div.x2-container div.channel-container-left,
div.x2-container div.x2-container div.channel-container-right {
  padding: 0 0;
}

div.x2-container div.x2-container div.channel-container-left img,
div.x2-container div.x2-container div.channel-container-right img {
  height: 50px;
  width: 50px;
}

div.x2-container div.x2-container div.channel-container-left div.channels-container,
div.x2-container div.x2-container div.channel-container-right div.channels-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
}

div.x2-container div.x2-container div.channel-container-left div.channels-container div.channel,
div.x2-container div.x2-container div.channel-container-right div.channels-container div.channel {
  margin-right: 15px;
  text-align: center;
}

div.x2-container section {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
}

div.x2-container section.build-it-yourself img {
  width: 200px;
}

div.x2-container section.build-it-yourself a.like-btn {
  background: #488a48;
  color: #fff;
}

div.x3-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

div.x3-container.nodge-down {
  margin-top: 20px;
}

div.x3-container div.flow-template {
  background: #fff;
  padding: 20px;
  word-break: break-word;
}

div.x3-container div.flow-template div.flow-template-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

div.x3-container div.flow-template div.flow-template-head div.flow-template-aciton-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

div.x3-container div.flow-template div.flow-template-head div.flow-template-aciton-container a {
  padding: 5px;
  color: #333;
}

div.x3-container div.flow-template div.flow-template-body {
  font-size: 14px;
}

div.x3-container div.flow-template a.use-template {
  color: green;
  text-decoration: none;
}

div.x3-container div.container {
  padding: 20px 10px;
  background: #fff;
}

div.x3-container div.container div.chart-container {
  width: 100%;
  height: 300px;
}

div.x3-container div.container.area-chart div.chart-container {
  width: 100%;
  height: 300px;
}

div.x3-container div.container.area-chart div.chart-container {
  width: 100%;
  height: 300px;
}

div.x3-container div.container.most-interacted div.content {
  padding-top: 10px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

div.x3-container div.container.most-interacted div.content div.interact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

div.x3-container div.container.most-interacted div.content div.interact span.number {
  margin: 0 20px;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background: #fff;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

div.x3-container div.container.most-interacted div.content div.interact div.content p {
  padding: 10px 0;
  font-size: 14px;
  color: #555;
}

div.x3-container div.container.most-interacted div.content div.interact div.content div.events {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

div.create-flow-using-templates {
  margin-top: 30px;
}

div.create-flow-using-templates div.flow-templates-container div.flow-template {
  background: #fff;
  padding: 20px;
}

div.create-flow-using-templates div.flow-templates-container div.flow-template div.flow-template-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

div.create-flow-using-templates div.flow-templates-container div.flow-template div.flow-template-head div.flow-template-aciton-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

div.create-flow-using-templates div.flow-templates-container div.flow-template div.flow-template-head div.flow-template-aciton-container a {
  padding: 5px;
  color: #333;
}

div.create-flow-using-templates div.flow-templates-container div.flow-template div.flow-template-body {
  font-size: 14px;
}

div.create-flow-using-templates div.flow-templates-container div.flow-template a.use-template {
  color: green;
  text-decoration: none;
}

div.flow-container div.head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

div.flow-container div.head h3 {
  margin-right: 15px;
}

div.flow-container div.head span {
  font-size: 13px;
}

div.flow-container div.flows-container table {
  width: 100%;
  font-size: 13px;
  border-collapse: collapse;
}

div.flow-container div.flows-container table tr td {
  padding: 10px;
  background: #fff;
}

div.flow-container div.flows-container table tr td span.status {
  padding: 3px 5px;
  background: lightblue;
}

div.flow-container div.flows-container table tr.table-head {
  background: #fff;
  margin-bottom: 10px;
}

div.flow-container div.flows-container table tr.table-head th {
  text-align: left;
  padding: 10px;
}

div.change-flowtype-container {
  margin: 25px 0;
}

div.change-flowtype-container ul li {
  list-style: none;
  display: inline;
  padding: 0 10px;
}

div.change-flowtype-container ul li a {
  padding: 10px;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  border-radius: 8px;
}

div.change-flowtype-container ul li a.active {
  background: #fff;
}

div.chart {
  background: #fff;
  padding: 10px;
  border-radius: 8px;
}

div.chart div.chart-container {
  height: 280px;
  overflow: hidden;
}

div.chart h3.title {
  padding-bottom: 10px;
}

div.chart div.indicator {
  padding: 15px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

div.chart div.indicator span {
  font-size: 14px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

div.chart div.indicator span span.indicator {
  height: 8px;
  width: 8px;
  background: #ddd;
  border-radius: 8px;
}

.recharts-wrapper,
.recharts-responsive-container {
  padding: 0;
  margin: 0;
}

/*# sourceMappingURL=base.scss.map */

.content__container_widgets {
  position: relative;
}

.parameters-container {
  height: 100%;
  overflow: hidden scroll;
  padding: 0 24px 16px 24px;
}

.sticky-save-btn-container {
  $horizontal: 24px;
  $vertical: 16px;
  padding: $vertical $horizontal;
  background-color: white;
  border-top: 1px solid $color-warm-lighter;
  display: none;
}

.mat-simple-snackbar {
  :first-child {
    min-width: 0;
    word-break: break-word;
  }
}

.row-narrow {
  $gutter: 0.5rem;

  &.row {
    margin-left: -$gutter;
    margin-right: -$gutter;
  }

  [class^="col"] {
    padding-left: $gutter;
    padding-right: $gutter;
  }
}

uni-box {
  .mat-table {
    border-radius: $radius-150;
    overflow: auto;
  }
}
