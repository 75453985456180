$color-purple-50: #f5f0ff;
$color-purple-100: #e2d4ff;
$color-purple-200: #cbb2fc;
$color-purple-300: #b08ef4;
$color-purple-400: #966ee5;
$color-purple-500: #6c3fc7;
$color-purple-600: #6236bb;
$color-purple-700: #532ba5;
$color-purple-800: #391c74;
$color-purple-900: #1f094a;

$color-orange-50: #fff4ed;
$color-orange-100: #fedec8;
$color-orange-200: #fec195;
$color-orange-300: #fea15e;
$color-orange-400: #f38a3f;
$color-orange-500: #e56910;
$color-orange-600: #c25100;
$color-orange-700: #a54800;
$color-orange-800: #702e00;
$color-orange-900: #451d01;

$color-teal-50: #e7feff;
$color-teal-100: #c6f9fb;
$color-teal-200: #a0eff2;
$color-teal-300: #69dfe3;
$color-teal-400: #3ccfd4;
$color-teal-500: #16c1c7;
$color-teal-600: #14a1a6;
$color-teal-700: #118b8f;
$color-teal-800: #0e5e61;
$color-teal-900: #0f3e40;

$color-pink-50: #ffecf5;
$color-pink-100: #fdcfe6;
$color-pink-200: #ff9acc;
$color-pink-300: #f174b2;
$color-pink-400: #e55ca1;
$color-pink-500: #ce478b;
$color-pink-600: #b93a79;
$color-pink-700: #9c2e65;
$color-pink-800: #67173f;
$color-pink-900: #4b0e2c;

$color-red-50: #ffeceb;
$color-red-100: #ffd3d2;
$color-red-200: #fd9591;
$color-red-300: #f86d68;
$color-red-400: #f15550;
$color-red-500: #e2423d;
$color-red-600: #c9312c;
$color-red-700: #ae2924;
$color-red-800: #5d1c1a;
$color-red-900: #420705;

$color-green-50: #e8fcf3;
$color-green-100: #befadc;
$color-green-200: #7bedb4;
$color-green-300: #4ce599;
$color-green-400: #1ad678;
$color-green-500: #18c36d;
$color-green-600: #16b163;
$color-green-700: #118a4d;
$color-green-800: #0b5c33;
$color-green-900: #0a3c23;

$color-yellow-50: #fff8e5;
$color-yellow-100: #fce7ac;
$color-yellow-200: #fad161;
$color-yellow-300: #f6bc1d;
$color-yellow-400: #edae02;
$color-yellow-500: #dba000;
$color-yellow-600: #c99300;
$color-yellow-700: #b58500;
$color-yellow-800: #8a6501;
$color-yellow-900: #664b00;

$color-neutral-0: #ffffff;
$color-neutral-50: #f7f6f6;
$color-neutral-100: #efefef;
$color-neutral-200: #e0e0e0;
$color-neutral-300: #c2c2c2;
$color-neutral-400: #a3a3a3;
$color-neutral-500: #898989;
$color-neutral-600: #6b6b6b;
$color-neutral-700: #474747;
$color-neutral-800: #333333;
$color-neutral-900: #171717;

$color-darkneutral-50: #f7f6f6;
$color-darkneutral-100: #efefef;
$color-darkneutral-150: #efefef;
$color-darkneutral-200: #e0e0e0;
$color-darkneutral-250: #efefef;
$color-darkneutral-300: #c2c2c2;
$color-darkneutral-400: #a3a3a3;
$color-darkneutral-500: #898989;
$color-darkneutral-600: #6b6b6b;
$color-darkneutral-700: #474747;
$color-darkneutral-800: #333333;
$color-darkneutral-900: #171717;

$color-blue-50: #e9f2ff;
$color-blue-100: #d1e3ff;
$color-blue-200: #91bdff;
$color-blue-300: #579aff;
$color-blue-400: #3888ff;
$color-blue-500: #1d76fc;
$color-blue-600: #0c62e4;
$color-blue-700: #0052cc;
$color-blue-800: #09316c;
$color-blue-900: #102648;
