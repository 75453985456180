// Border
// ------------------------------------------
$border-radius: 4px;
$border-circle: 50%;

$radius-0: 0px;
$radius-025: 2px;
$radius-050: 4px;
$radius-075: 6px;
$radius-100: 8px;
$radius-150: 12px;
$radius-200: 16px;
$radius-300: 24px;
$radius-400: 32px;
$radius-500: 40px;
$radius-600: 48px;
$radius-700: 56px;
$radius-800: 64px;
