@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
$font-path: '/assets/fonts';

@if variable-exists(is-symfony) and $is-symfony {
  $font-path: '/build/fonts';
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('#{$font-path}/GothamRounded-Book.eot');
  src: local('Gotham Rounded Book'), local('GothamRounded-Book'),
      url('#{$font-path}/GothamRounded-Book.eot?#iefix') format('embedded-opentype'),
      url('#{$font-path}/GothamRounded-Book.woff2') format('woff2'),
      url('#{$font-path}/GothamRounded-Book.woff') format('woff'),
      url('#{$font-path}/GothamRounded-Book.ttf') format('truetype'),
      url('#{$font-path}/GothamRounded-Book.svg#GothamRounded-Book') format('svg');
  font-weight: $font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('#{$font-path}/GothamRounded-Medium.eot');
  src: local('Gotham Rounded Medium'), local('GothamRounded-Medium'),
      url('#{$font-path}/GothamRounded-Medium.eot?#iefix') format('embedded-opentype'),
      url('#{$font-path}/GothamRounded-Medium.woff2') format('woff2'),
      url('#{$font-path}/GothamRounded-Medium.woff') format('woff'),
      url('#{$font-path}/GothamRounded-Medium.ttf') format('truetype'),
      url('#{$font-path}/GothamRounded-Medium.svg#GothamRounded-Medium') format('svg');
  font-weight: $font-weight-medium;
  font-style: normal;
}

// @TODO: uncomment the code below, when there is no more font.scss added in monolith

// @font-face {
//   font-family: 'Tahoma';
//   src: url('#{$font-path}/Tahoma.eot');
//   src: local('Tahoma'), local('Tahoma'),
//       url('#{$font-path}/Tahoma.eot?#iefix') format('embedded-opentype'),
//       url('#{$font-path}/Tahoma.woff2') format('woff2'),
//       url('#{$font-path}/Tahoma.woff') format('woff'),
//       url('#{$font-path}/Tahoma.ttf') format('truetype'),
//       url('#{$font-path}/Tahoma.svg#Tahoma') format('svg');
//   font-weight: $font-weight-regular;
//   font-style: normal;
//   unicode-range: U+0600-06FF, U+0750-077F, U+08A0-08FF, U+FB50-FDFF, U+FE70-FEFF, U+10E60-10E7F, U+1EC70-1ECBF, U+1ED00-1ED4F, U-1EE00-1EEFF;
// }

// @font-face {
//   font-family: 'Tahoma';
//   src: url('#{$font-path}/Tahoma-Bold.eot');
//   src: local('Tahoma Bold'), local('Tahoma-Bold'),
//       url('#{$font-path}/Tahoma-Bold.eot?#iefix') format('embedded-opentype'),
//       url('#{$font-path}/Tahoma-Bold.woff2') format('woff2'),
//       url('#{$font-path}/Tahoma-Bold.woff') format('woff'),
//       url('#{$font-path}/Tahoma-Bold.ttf') format('truetype'),
//       url('#{$font-path}/Tahoma-Bold.svg#Tahoma-Bold') format('svg');
//   font-weight: $font-weight-medium;
//   font-style: normal;
//   unicode-range: U+0600-06FF, U+0750-077F, U+08A0-08FF, U+FB50-FDFF, U+FE70-FEFF, U+10E60-10E7F, U+1EC70-1ECBF, U+1ED00-1ED4F, U-1EE00-1EEFF;
// }
