// Shadows
// ------------------------------------------
$shadow-soft: 0 2px 5px 0 rgba($color-black, .2);
$shadow-box: 0 2px 4px 2px rgba($color-dark, .1);
$shadow-card: 0 2px 4px 4px rgba($color-black, .06);
$shadow-wide: 0 5px 12px 2px rgba($color-black, .12);
$shadow-tile: 2px 2px 4px 1px rgba($color-black, .15);

$shadow: 0 3px 6px 0 rgba($color-shadow, .2);
$shadow-light: 0 2px 4px 0 rgba($color-shadow, .1);

// Shadows
// ------------------------------------------
$shadow-soft: 0 2px 5px 0 rgba($color-black, .2);
$shadow-box: 0 2px 4px 2px rgba($color-dark, .1);
$shadow-card: 0 2px 4px 4px rgba($color-black, .06);
$shadow-wide: 0 5px 12px 2px rgba($color-black, .12);
$shadow-tile: 2px 2px 4px 1px rgba($color-black, .15);

$shadow: 0 3px 6px 0 rgba($color-shadow, .2);
$shadow-light: 0 2px 4px 0 rgba($color-shadow, .1);

$shadow-xs: 0 1px 2px 0 rgba(0, 0, 0, .05);
$shadow-sm: 0 1px 3px 0 rgba(0, 0, 0, .10), 0 1px 2px 0 rgba(0, 0, 0, .06);
$shadow-md: 0 4px 6px -1px rgba(0, 0, 0, .08), 0 2px 4px -1px rgba(0, 0, 0, .06);
$shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, .08), 0 4px 6px -2px rgba(0, 0, 0, .05);
$shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, .10), 0 10px 10px -5px rgba(0, 0, 0, .04);
$shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, .25);
$shadow-overlay:  0 0 0 1px rgba(0, 0, 0, .10), 0 24px 48px 0 rgba(0, 0, 0, .03), 0 10px 18px 0 rgba(0, 0, 0, .03), 0 5px 8px 0 rgba(0, 0, 0, .04), 0 2px 4px 0 rgba(0, 0, 0, .04);
