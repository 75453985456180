/* Paper */
.joint-paper.joint-theme-default {
    background-color: #FFFFFF;
}
/* Paper */

/*  Links  */
.joint-link.joint-theme-default .connection-wrap {
    stroke: #000000;
    stroke-width: 15;
    stroke-linecap: round;
    stroke-linejoin: round;
    opacity: 0;
    cursor: move;
}
.joint-link.joint-theme-default .connection-wrap:hover {
    opacity: .4;
    stroke-opacity: .4;
}
.joint-link.joint-theme-default .connection {
    stroke-linejoin: round;
}
.joint-link.joint-theme-default .link-tools .tool-remove circle {
    fill: #FF0000;
}
.joint-link.joint-theme-default .link-tools .tool-remove path {
    fill: #FFFFFF;
}

/* <circle> element inside .marker-vertex-group <g> element */
.joint-link.joint-theme-default .marker-vertex {
    fill: #1ABC9C;
}
.joint-link.joint-theme-default .marker-vertex:hover {
    fill: #34495E;
    stroke: none;
}

.joint-link.joint-theme-default .marker-arrowhead {
    fill: #1ABC9C;
}
.joint-link.joint-theme-default .marker-arrowhead:hover {
    fill: #F39C12;
    stroke: none;
}

/* <circle> element used to remove a vertex */
.joint-link.joint-theme-default .marker-vertex-remove {
    fill: #FFFFFF;
}
/*  Links  */
