@import "src/styles/styles";

/* START OF GLOBAL CSS RESET */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

/* END OF GLOBAL CSS RESET */

/* START OF CSS VARIABLES */
:root {
  --primary-dark: #304252;
  --primary-green: #5ea79b;
  --primary-grey: #eaedf0;
  --primary-font: "Montserrat", sans-serif;

  --white: #fff;
  --dark: #000;
  --danger: #de2f40;
  --blue: rgb(49, 127, 190);
  --orange: #f4b65c;
  --green: #29cdb3;
  --ashen: #838e99;
  --pink: #f381ab;
  --purple: #a986fa;
  --lime: #b6d155;

  --light-blue: #d3f1ff;
  --light-green: #e8f5f7;
  --light-grey: #f0f4f5;
  --light-grey-2: #fafafa;
  --light-dark: #a1aab3;

  --grey: #eef0f1;
  --grey-2: #f2f2f2;

  --text-grey: #555;

  --card-heading: #ecf0f3;
}

/* END OF CSS VARIABLES */

html {
  /* defines what 1rem equals */
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: var(--primary-font);
  color: var(--text-grey);
}

// body::-webkit-scrollbar {
//   width: 0.5rem;
// }

// body::-webkit-scrollbar-track {
//   background: var(--grey);
// }

// body::-webkit-scrollbar-thumb {
//   background: var(--primary-dark);
//   border-radius: 25px;
// }

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.header {
  height: auto;
  //z-index: 1;
}

.show_on_hover {
  display: none;
}

h1:hover + .show_on_hover {
  display: block;
  color: black;
}

/* START OF TOP HEADER */
.header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey);
}

/* START OF TOP LEFT HEADER */
.header__top_left {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.header__top_left_icon {
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  background-color: var(--primary-dark);
  cursor: pointer;
}

.header__top_left_icon i {
  font-size: 1.5rem;
  color: var(--white);
}

.header__top_left_text {
  font-size: 1.5rem;
  font-weight: 500;
}

/* END OF TOP LEFT HEADER */

/* START OF TOP RIGHT HEADER */
.header__top_right {
  display: flex;
}

.header__top_right_tile {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  border-right: 2px solid var(--grey-2);
}

.header__top_right_tile:first-child {
  border-left: 2px solid var(--grey-2);
}

.header__top_right_tile i {
  font-size: 1.4rem;
  padding: 0 1.5rem;
  color: var(--text-grey);
  opacity: 0.5;
}

.icon--large {
  font-size: 2rem !important;
}

.header__top_right_tile img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin: 0 1.5rem;
}

.header__top_right_tile p {
  font-size: 1.23rem;
  font-weight: 600;
  text-transform: capitalize;
}

.header__top_right_badge {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
}

.header__top_right_badge i {
  font-size: 1.6rem;
}

.header__top_right_badge_wrapper {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  background-color: var(--danger);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
  left: 50%;
}

.header__top_right_badge p {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--white);
}

/* END OF TOP RIGHT HEADER */
/* END OF TOP HEADER */

/* START OF BOTTOM HEADER */
.header__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* START OF BOTTOM LEFT HEADER */
.header__bottom_left {
  display: flex;
  align-items: center;
}

.header__bottom_left p {
  font-size: 1.5rem;
  font-weight: 500;
  margin-right: 1rem;
  margin-left: 1rem;
}

.header__bottom_left_badge {
  padding: 0.5rem;
  background-color: var(--light-blue);
  color: var(--blue);
  font-weight: 800;
  margin: 0 2rem;
  border-radius: 2px;
}

.header__bottom_left_icon {
  padding: 0.5rem 0.7rem;
  border-radius: 3px;
  border: 1px solid var(--grey);
  margin-right: 2rem;
}

.header__bottom_left_icon i {
  font-size: 1.2rem;
  opacity: 0.8;
}

.header__bottom_left span {
  font-size: 1.2rem;
  font-weight: 300;
}

/* END OF BOTTOM LEFT HEADER */

/* START OF BOTTOM RIGHT HEADER */
.header__bottom_right {
  display: flex;
  align-items: center;
}

.header__bottom_right_tile {
  display: flex;
  align-items: center;
}

.header__bottom_right_tile_icon {
  padding: 0.5rem 0.7rem;
  border-radius: 3px;
  border: 1px solid var(--grey);
  margin-right: 1rem;
  opacity: 0.9;
  cursor: pointer;
}

.header__bottom_right_btn {
  padding: 0.5rem 0.7rem;
  border-radius: 3px;
  color: black;
  border: 1px solid var(--grey);
  margin-right: 1rem;
  font-family: var(--primary-font);
  background: var(--white);
  cursor: pointer;
}

.header__bottom_right_btn--solid {
  padding: 0.8rem 1.2rem;
  border-radius: 6px;
  border: 1px solid var(--grey);
  margin-right: 1rem;
  font-family: var(--primary-font);
  background: var(--primary-dark);
  color: var(--white);
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}

.header__bottom_right_btn i {
  opacity: 0.5;
  margin-left: 0.5rem;
}

/* START OF BOTTOM RIGHT HEADER */
/* START OF BOTTOM HEADER */
/* END OF HEADER */

/* START OF CONTENT */
.content {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: var(--light-grey);
}

/* START OF CARD */
.content__card {
  width: 40rem;
  margin: 0 auto;
  box-shadow: -10px 0 8px -8px rgba(0, 0, 0, 0.15), 10px 0 8px -8px rgba(0, 0, 0, 0.14);
}

/* START OF CARD HEADER */
.content__card_header {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--card-heading);
  text-transform: uppercase;
  color: var(--primary-dark);
}

.content__card_header i {
  font-size: 1.5rem;
}

/* END OF CARD HEADER */

/* START OF CARD CONTENT */
.content__card_tile {
  background-color: var(--white);
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 2px solid var(--grey);
  cursor: pointer;
  transition: background 300ms ease;
}

.content__card_tile:hover {
  background: rgba(255, 255, 255, 0.6);
}

.content__card_tile_icon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  margin-right: 2rem;
  justify-content: center;
  align-items: center;
  background: var(--light-green);
  border: 1px solid var(--primary-green);
}

.content__card_tile_icon i {
  font-size: 1.5rem;
  color: var(--primary-green);
}

.content__card_tile_info {
  flex: 1;
}

.content__card_tile_info h1 {
  font-weight: 600;
  color: var(--primary-dark);
}

.content__card_tile_info p {
  font-size: 1.4rem;
  margin-top: 1rem;
  line-height: 2.5ch;
}

/* END OF CARD CONTENT */
/* END OF CONTENT */

/* START OF WIDGETS CONTENT CONTAINER -- @ next.html */
.content__container {
  min-height: 1500px;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: var(--light-grey);
  background-image: -webkit-repeating-radial-gradient(
    center center,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 1px,
    transparent 1px,
    transparent 100%
  );
  background-size: 16px 16px;
}

.content__container_widgets {
  width: 22rem;
  z-index: 1;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
}

.content__container_widgets_header {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid var(--primary-grey);
  background: white;
}

.content__container_widgets_header h1 {
  color: $color-dark;
  font-size: 1.3rem;
  font-weight: 700;
  opacity: 0.6;
  text-transform: uppercase;
}

.content__container_widgets_header i {
  font-size: 1.5rem;
  opacity: 0.5;
}

.content__container_widgets_tile {
  background: var(--white);
  border-top: 1px solid $color-dark-lighter;
}

.content__container_widgets_tile_header {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content__container_widgets_tile_header h1 {
  font-size: 1.24rem;
  text-transform: uppercase;
  opacity: 0.8;
}

.content__container_widgets_tile_header i {
  font-size: 1.3rem;
  text-transform: uppercase;
  opacity: 0.5;
}

.content__container_widgets_tile_content {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: var(--light-grey-2);
}

.content__container_widgets_tile_content_icon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin-right: 2rem;
}

.content__container_widgets_tile_content_icon i {
  font-size: 1.45rem;
  opacity: 0.7;
}

.content__container_widgets_tile_content_info {
  flex: 1;
}

.content__container_widgets_tile_content_info h1 {
  font-size: 1.4rem;
  text-transform: capitalize;
  opacity: 0.8;
  /* margin-top: 1em; */
}

.content__container_widgets_tile_content_info p {
  font-size: 1.29rem;
  margin-top: 1rem;
  line-height: 2.5ch;
}

/* END OF WIDGETS CONTENT CONTAINER -- */

/* START OF CONTENT CONTAINER TOOLS */
.content__container_tools {
  width: 30rem;
  position: fixed;
  z-index: 1;
  top: 130px;
  overflow-y: auto;
  height: 400px;
}

.content__container_tools_call-box {
  width: 23rem;
  margin: 4rem auto 0 auto;
  padding: 1.5rem;
  background: var(--white);
  border-radius: 5px;
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.content__container_tools_call-box-icon--floating {
  position: absolute;
  top: -1.8rem;
  left: 50%;
  transform: translateX(-50%);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--white);
  background: var(--primary-dark);
}

.content__container_tools_call-box-icon--floating i {
  color: var(--white);
  font-size: 1.3rem;
}

.content__container_tools_call-box-icon {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--light-green);
  border: 1px dotted var(--primary-green);
  animation: alertPulse 2s infinite;
  cursor: pointer;
}

@-webkit-keyframes alertPulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(94, 167, 155, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(94, 167, 155, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(94, 167, 155, 0);
  }
}

@keyframes alertPulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(94, 167, 155, 0.4);
    box-shadow: 0 0 0 0 rgba(94, 167, 155, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(94, 167, 155, 0);
    box-shadow: 0 0 0 10px rgba(94, 167, 155, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(94, 167, 155, 0);
    box-shadow: 0 0 0 0 rgba(94, 167, 155, 0);
  }
}

.content__container_tools_call-box-icon i {
  font-size: 1.3rem;
  color: var(--primary-green);
}

.content__container_tools_call-box-info h1 {
  font-size: 1.5rem;
  opacity: 0.8;
}

.content__container_tools_call-box-info p {
  font-size: 1.3rem;
  opacity: 0.8;
  margin-top: 0.5rem;
}

.content__container_tools_icon {
  margin: 2px auto;

  .uni-button-icon {
    transition: unset !important;
  }
}

.duro {
  color: red;
}

.content__container_tools_icon_big {
  cursor: pointer;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(207, 5, 5, 0.3);
}

.content__container_tools_icon i {
  //font-size: 1rem;
  //opacity: 0.5;
  color: var(--light-dark);
}

.content__container_tools_list {
  border-radius: 3px;
  border: 1.5px solid rgba(0, 0, 0, 0.15);
}

.content__container_tools_item {
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--white);
  cursor: pointer;
  transition: background 300ms ease;
}

.content__container_tools_item:hover {
  background: var(--light-grey-2);
}

.content__container_tools_item:not(:last-child) {
  border-bottom: 1px solid var(--light-grey);
}

.content__container_tools_item:first-child {
  background: var(--primary-grey);
}

.content__container_tools_item h1 {
  font-size: 1.3rem;
  text-transform: uppercase;
}

.content__container_tools_item i {
  font-size: 1.2rem;
  //opacity: 0.5;
}

.content__container_tools_item:first-child i {
  font-size: 1.5rem;
}

/* END OF CONTENT CONTAINER TOOLS */

/* START OF CONTENT CONTAINER ACTIONS */
.content__container_actions {
  z-index: 1;
  width: 30rem;
}

.content__container_actions_variables_heading {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--light-grey-2);
}

.content__container_actions_variables_heading h1 {
  font-size: 1.3rem;
  font-weight: 700;
  opacity: 0.6;
  text-transform: uppercase;
}

.content__container_actions_variables_heading i {
  font-size: 1.5rem;
  opacity: 0.5;
}

.content__container_actions_variables_info {
  background: var(--white);
  padding: 1.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.content__container_actions_variables_info_badge {
  padding: 0.7rem 1rem;
  border-radius: 25px;
  background: var(--light-dark);
  color: var(--white);
  //text-transform: capitalize;
  margin-right: 1.5rem;
  font-weight: 500;
  font-size: 1.2rem;

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

.content__container_actions_variables_info_badge:not(:last-child) {
  margin-bottom: 1rem;
}

.content__container_actions_params {
  margin-top: 1rem;
  background: var(--white);
}

.content__container_actions_params_heading {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--light-grey-2);
}

.content__container_actions_params_heading h1 {
  font-size: 1.3rem;
  font-weight: 700;
  opacity: 0.6;
  text-transform: uppercase;
}

.content__container_actions_params_heading i {
  font-size: 1.5rem;
  opacity: 0.5;
}

.content__container_actions_params_tile {
  //padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border-bottom: 1px solid var(--grey);
}

.marginTop10 {
  margin-top: 10px;
}

/* .border_bottom{
  border-bottom: 1px solid var(--grey);
  padding-bottom: 20px;
} */
.content__container_actions_params_tile h1 {
  font-size: 1.2rem;
  font-weight: 700;
  opacity: 0.9;
  text-transform: capitalize;
}

.content__container_actions_params_tile i {
  font-size: 1.5rem;
  opacity: 0.5;
}

.content__container_actions_params_form {
  padding: 1.5rem;
}

.form_item {
  margin-bottom: 2rem;
}

.form_item label {
  display: block;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.form_item label > i {
  opacity: 0.7;
  margin-left: 1rem;
}

.form_item input {
  padding: 1rem;
  display: block;
  //width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: var(--primary-font);
}

.form_item select {
  padding: 1rem;
  display: block;
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: var(--primary-font);
}

.form_item textarea {
  padding: 1rem;
  display: block;
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: var(--primary-font);
}

.form_item p {
  font-size: 1.3rem;
  font-weight: 500;
  opacity: 0.5;
  margin: 1rem 0 0.5rem 0;
}

/* .form_item span {
  color: var(--primary-green);
  font-size: 1.3rem;
  font-weight: 500;
} */

.form_item_btn {
  display: block;
  padding: 0.8rem 2rem;
  border: 1px solid var(--light-dark);
  background: var(--light-dark);
  font-family: var(--primary-font);
  font-weight: 500;
  text-transform: capitalize;
  color: var(--white);
  margin-left: auto;
  border-radius: 3px;
  width: 100%;
  margin-top: 10px;
}

/* END OF CONTENT CONTAINER ACTIONS */

/* START OF CONTENT CONTAINER BRANCH */
.content__container_branch {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  //margin-top: 4rem;
}

.card_min_width {
  min-width: 100px;
}

.content__container_branch_card {
  box-shadow: 0px 1px 3px 0px #00000014;
  padding: 0 0 0 0;
  display: flex;
  align-items: center;
  border-radius: $radius-150;
  background-color: $color-background-neutral-subtlest;
  width: fit-content;
  margin: 0 auto;
  justify-content: space-between;
}

.content__container_branch_card_icon.--fixed {
  margin: 0 0 -2px 0;
  z-index: 2;
  background-color: #000;
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  background-color: $color-brand-black;
}

.content__container_branch_card_icon.--fixed i {
  font-size: 1.3rem;
  color: var(--white);
}

.content__container_branch_card_icon {
  margin: 6px 6px 6px 6px !important;
  border-radius: $radius-100;
  box-shadow: none;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content__container_branch_card_icon_left {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  margin-left: 2rem;
}

.content__container_branch_card_icon i {
  font-size: 18px;
  color: var(--orange);
}

.content__container_branch_card_icon.--blue i {
  color: var(--blue);
}

.content__container_branch_card_icon.--purple i {
  color: var(--purple);
  font-size: 1.7rem;
}

.content__container_branch_card_icon.--pink i {
  color: var(--pink);
  font-size: 1.7rem;
}

.content__container_branch_card_icon.--lime i {
  color: var(--lime);
  font-size: 1.7rem;
}

.content__container_branch_card_title {
  font-size: 1.4rem;
  font-weight: 500;
  //opacity: 0.8;
  color: $color-text-primary;
  white-space: nowrap;
  flex-grow: 0.5;
  text-align: center;
  margin-inline-end: $space-200;
  text-transform: capitalize;
}

.content__container_branch_card_title.--small {
  font-size: 1.2rem;
}

.content__container_branch_arrow {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* width: 3rem; */
  height: 4rem;
}

.content__container_branch_arrow.--spacer {
  margin: 2rem 0 0.5rem 0;
}

.content__container_branch_arrow_dot {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: var(--ashen);
  position: relative;
  opacity: 0.7;
}

.content__container_branch_arrow_dot::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 3rem;
  width: 1.1px;
  background-color: var(--ashen);
}

.content__container_branch_arrow_dot::after {
  content: "";
  position: absolute;
  bottom: -2.2rem;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  width: 1.2rem;
  height: 1.2rem;
  border-left: 2px solid var(--ashen);
  border-bottom: 2px solid var(--ashen);
}

.content__container_branch_row {
  width: 45rem;
  /* border-top-right-radius: 25px;
  border-top-left-radius: 25px; */
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content__container_branch_col {
  width: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content__container_branch_col_card {
  background-color: var(--ashen);
  color: var(--white);
  padding: 1rem;
  border-radius: 5px;
}

.content__container_branch_col_card p {
  font-size: 1.2rem;
  line-height: 2.3ch;
}

.content__container_branch_new_node {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--primary-dark);
  animation: pulse 2s infinite;
  margin-top: 1rem;
}

.content__container_branch_new_node:hover {
  animation: none;
}

.content__container_branch_new_node.--disabled {
  visibility: hidden;
  opacity: 0;
}

.content__container_branch_new_node i {
  font-size: 1.5rem;
  color: var(--white);
  transition: opacity 300ms ease;
}

.content__container_branch_new_node:hover i {
  opacity: 0.9;
}

/* END OF CONTENT CONTAINER BRANCH */

/* FLOATING ACTION BUTTON -- NOT IMPORTANT */
.floating-action--btn {
  position: fixed;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: var(--primary-dark);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  left: 95%;
  bottom: 2rem;
  transform: translateX(-95%);
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
  animation: pulse 2s infinite;
}

.floating-action--btn i {
  font-size: 1.6rem;
}

.floating-action--btn:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/*END OF -- NOT IMPORTANT */

/* START OF MEDIA QUERIES -- @1024px, 950px, 670px, 500px, 400px, 320px */
@media only screen and (max-width: 1080px) {
  /* NEXT PAGE QUERIES */
  .content__container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  /* NEXT PAGE QUERIES */

  /* FINAL PAGE QUERIES */
  .content__container_branch {
    order: -1;
    //margin-bottom: 4rem;
  }
  /* FINAL PAGE QUERIES */

  .content__container_tools {
    order: -1;
    margin-bottom: 4rem;
  }

  .content__container_actions {
    margin-top: 4rem;
  }
}

@media only screen and (max-width: 1024px) {
  .content {
    min-height: 100vh;
  }
}

@media only screen and (max-width: 950px) {
  .header__bottom_left span {
    display: none;
  }

  .header__bottom_left_icon {
    display: none;
  }
}

@media only screen and (min-width: 670px) {
  .icon--sm {
    display: none;
  }
}

@media only screen and (max-width: 670px) {
  .header__bottom {
    display: none;
  }

  .header__top {
    border: none;
  }

  .header__top_right_tile {
    display: none;
  }

  .header__top_right_badge {
    display: none;
  }

  .icon--sm {
    font-size: 2rem;
    display: block;
    margin-right: 2rem;
    cursor: pointer;
  }

  .content__card {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 500px) {
  .content {
    padding: 0 4rem;
  }

  .content__card {
    width: 100%;
    margin-bottom: 2rem;
  }

  .content__card_tile {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content__card_tile_icon {
    margin-bottom: 2rem;
  }

  .content__card_tile_info {
    text-align: center;
  }

  .content__card_tile_info p {
    width: 90%;
    margin: 1rem auto;
  }

  /* NEXT PAGE QUERIES */
  .content__container_widgets {
    width: 100%;
  }

  .content__container_tools_call {
    width: 100%;
  }

  .content__container_tools {
    width: 100%;
  }

  .content__container_actions {
    width: 100%;
  }
  /* NEXT PAGE QUERIES */

  /* FINAL PAGE QUERIES  */
  .content__container_branch {
    margin-bottom: 2.5rem;
  }

  .content__container_branch_row {
    flex-direction: column;
    width: 100%;
  }

  .content__container_branch_col {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 400px) {
  .content {
    padding: 0 2rem;
  }
}

@media only screen and (max-width: 320px) {
  .header__top_left_icon {
    height: 4.5rem;
  }
}

@media screen and (max-width: 600px) {
  #widget_section {
    visibility: hidden;
    clear: both;
    display: none;
  }
  #buttonWrapper {
    visibility: hidden;
    clear: both;
    display: none;
  }
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: white;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  display: none;
  overflow: hidden;
}

.accordion_widget {
  background-color: white;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion_widget:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.panel_widget {
  display: none;
  overflow: hidden;
}

button:disabled {
  cursor: not-allowed !important;
}

.uni-app-logged__overlay.is-active {
  z-index: 1;
}

.arrow-circle {
  background-color: green;
}
