$dimensions-regular: ('mobile': 14px, 'mobile-big': 16px, 'desktop': 16px);
$dimensions-small: ('mobile': 16px, 'mobile-big': 32px, 'desktop': 32px);
$dimensions-medium: ('mobile': 20px, 'mobile-big': 36px, 'desktop': 40px);
$dimensions-big: ('mobile': 20px, 'mobile-big': 36px, 'desktop': 72px);
$dimensions-form: ('mobile': 8px, 'mobile-big': 10px, 'desktop': 10px);
$dimensions-card: ('mobile': 16px, 'mobile-big': 24px, 'desktop': 24px);
$dimensions-card-description: ('mobile': 16px, 'mobile-big': 24px, 'desktop': 32px);
$dimensions-form-medium: ('mobile': 10px, 'mobile-big': 15px, 'desktop': 24px);
$dimensions-extra-small: ('mobile': 4px,  'mobile-big': 8px, 'desktop': 8px);
$dimensions-large: ('mobile': 60px,  'mobile-big': 60px, 'desktop': 200px);

$space-0: 0px;
$space-025: 2px;
$space-050: 4px;
$space-100: 8px;
$space-150: 12px;
$space-200: 16px;
$space-250: 20px;
$space-300: 24px;
$space-400: 32px;
$space-500: 40px;
$space-600: 48px;
$space-700: 56px;
$space-800: 64px;

$sidebar--width: 286px;
$sidebar-gutter: 24px;
$navbar--height: 70px;
$navbar--height-mobile: $navbar--height - 14;
$input-height: 44px;
$margin-medium: 16px;

@mixin dimensions($property, $value) {
  #{$property}: map-get($value, 'mobile');

  @include min-screen($mobile-big) {
    #{$property}: map-get($value, 'mobile-big');
  }

  @include min-screen($desktop) {
    #{$property}: map-get($value, 'desktop');
  }
}

@mixin margin($value) {
  @include dimensions(margin, $value);
}

@mixin margin-v($value) {
  @include dimensions(margin-top, $value);
  @include dimensions(margin-bottom, $value);
}

@mixin margin-h($value) {
  @include dimensions(margin-right, $value);
  @include dimensions(margin-left, $value);
}

@mixin padding($value) {
  @include dimensions(padding, $value);
}

@mixin padding-v($value) {
  @include dimensions(padding-top, $value);
  @include dimensions(padding-bottom, $value);
}

@mixin padding-h($value) {
  @include dimensions(padding-right, $value);
  @include dimensions(padding-left, $value);
}
