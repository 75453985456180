body.rtl {
  .country-list .flag-box,
  .country-list .country-name {
    margin-right: 0;
    margin-left: 12px;
  }

  input.mat-form-field-autofill-control {
    padding-right: 14px;
    padding-left: 55px;
    text-align: right;
  }

  .intl-tel-input .selected-flag .iti-arrow {
    right: auto;
    left: 8px;
  }

  .iti--allow-dropdown .iti__flag-container,
  .iti--separate-dial-code .iti__flag-container {
    right: 0;
    left: auto;
  }

  .iti--allow-dropdown input,
  .iti--allow-dropdown input[type=text],
  .iti--allow-dropdown input[type=tel],
  .iti--separate-dial-code input,
  .iti--separate-dial-code input[type=text],
  .iti--separate-dial-code input[type=tel] {
    padding-right: 84px !important;
    padding-left: 8px !important;
    margin-right: 0;
  }


  .iti__arrow {
    margin-right: 6px;
    margin-left: 0;
  }

  .iti__country {
    display: flex;
    align-items: center;
    text-align: right;
  }

  .iti__flag-box,
  .iti__country-name {
    margin-right: 0;
    margin-left: 6px;
  }

  .mat-calendar-arrow {
    margin: 0 5px 0 0;
  }

  .mat-calendar-body-label {
    text-align: right;
  }

  .mat-calendar-next-button,
  .mat-calendar-previous-button {
    transform: scaleX(-1);
  }

  .mat-datepicker-content .mat-calendar {
    direction: ltr;
  }

  .iti__dial-code {
    order: 1;
  }

  .iti input {
    text-align: right;
    direction: ltr;
    unicode-bidi: embed;
  }

  .mat-radio-label-content {
    padding-right: 8px !important;
    padding-left: 0 !important;
  }

  .mat-select-arrow {
    margin-right: 0;
    margin-left: 13px;
  }

  .mat-select-arrow-wrapper {
    padding-right: 8px;
    padding-left: 0;
  }

  .mat-option,
  .mat-optgroup-label,
  .mat-select-placeholder,
  .mat-select-value {
    text-align: right;
    direction: ltr;
  }

  .mat-option {
    justify-content: flex-end;
  }

  .uni-language-switcher .mat-select-value {
    text-align: left;
  }

  .mat-tooltip {
    direction: rtl;
  }

  .mat-tooltip--info {
    &::after {
      right: auto;
      left: calc(50% - 10px);
    }
  }
}
