// Typography - fonts
// ------------------------------------------
$font-family-primary: 'Inter', sans-serif;

.rtl {
  $font-family-primary: 'Gotham Rounded', 'Tahoma';
}

$font-family-awesome: 'Font Awesome 6 Pro';

// Typography - weight
// ------------------------------------------
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Typography settings
// ------------------------------------------
$font-caption: 12px;
$font-caption-rtl: 18px;
$font-body: 14px;
$font-body-rtl: 16px;
$font-subheader: 18px;
$font-subheader-rtl: 22px;
$font-extraLarge: 32px;

@function if-important($important){
  @return #{if($important, '!important', '')};
}

@mixin font($value, $important: false) {
  font-size: 10px if-important($important);
  line-height: 18px if-important($important);

  @media screen and (min-width: $mobile) {
    font-size: $value if-important($important);
    line-height: calc(#{$value} + 10px) if-important($important);
  }
}
