body {
  @include font($font-body);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  overflow-x: hidden;
  direction: ltr;
  background-color: $color-black-brighter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: $font-family-primary;
  color: $color-dark;

  &.rtl {
    direction: rtl;
  }
}

a, a:hover {
  text-decoration: inherit;
  color: inherit;
}

.ltr-only {
  /*rtl:ignore*/
  direction: ltr;
}

.rtl-phonenumber {
  /*rtl:ignore*/
  direction: ltr;
  /*rtl:ignore*/
  text-align: right;
}

button {
  border: none;
  outline: 0;

  &:active,
  &:hover {
    box-shadow: none;
    outline: 0;
  }

  &::-moz-focus-inner {
    border: 0;
  }
}
