// Material - settings
// ------------------------------------------
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~@angular/material/theming';

$general-typography: mat-typography-config(
  $font-family: $font-family-primary
);
@include mat-core($general-typography);
$unifonic-app-primary: mat-palette($mat-teal);
$unifonic-app-accent: mat-palette($mat-teal, A700);
$unifonic-app-warn: mat-palette($mat-red);
$unifonic-app-theme: mat-light-theme($unifonic-app-primary, $unifonic-app-accent, $unifonic-app-warn);
@include angular-material-theme($unifonic-app-theme);

.mat-date-range-input-container {
  padding-left: 16px;
  padding-right: 32px;
}

.mat-date-range-input-end-wrapper {
  .mat-date-range-input-separator-hidden + & {
    max-width: 0;
  }
}

.mat-date-range-input-end-wrapper input.uni-input {
  padding-right: 0;
  padding-left: 0;
}

div.mat-date-range-input-start-wrapper {
  max-width: none;
}

.mat-date-range-input-start-wrapper input.uni-input {
  height: 100%;;
  margin: 0;
  padding: 0;
  text-align: right;

  &:placeholder-shown {
    text-align: left;
  }
}

.mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0 !important;
}

.mat-form-field-flex {
  display: block !important;
}

.mat-select-trigger {
  padding: 14px;
  box-sizing: border-box;
}

.mat-form-field.mat-form-field--link,
.mat-form-field.mat-form-field-type-mat-select.mat-form-field--link {
  padding: 0;
  border: 0;
  color: $color-black;

  .mat-select-placeholder,
  .mat-form-field-hide-placeholder .mat-select-placeholder {
    color: $color-black !important;
    -webkit-text-fill-color: $color-black !important;
    font-weight: $font-weight-medium;
  }

  .mat-select {
    height: auto;
  }

  .mat-select-trigger {
    display: flex;
    padding: 0;
  }

  .mat-select-value {
    display: inline;
    max-width: initial;
    min-height: 16px;
  }

  .mat-select-arrow-wrapper {
    padding-left: 8px;
  }

  .mat-select .mat-select-arrow-wrapper .mat-select-arrow {
    margin: 2px;
    font-size: 10px;
    font-weight: $font-weight-medium;
  }
}

.cdk-overlay-pane .mat-select-panel {
  margin-top: 0;
}

.mat-menu-panel {
  min-height: 48px !important;
  border-radius: $radius-100 !important;
  padding: $space-100 $space-0 !important;
  box-shadow: $shadow-overlay !important;
  .mat-menu-content:not(:empty) {
    padding: 0 !important;
  }

  .mat-menu-item {
    line-height: 20px;
    padding: $space-050 $space-150;
    height: 28px;

    &:hover, &:hover:not([disabled]), &.cdk-program-focused:not([disabled]), &.cdk-keyboard-focused:not([disabled]), &-highlighted:not([disabled]) {
      background: $color-background-neutral-subtlest-hover;
      box-shadow: none !important;
    }

    &.btn, &.btn.focus, &.btn:focus {
      box-shadow: none;
    }

    .uni-button {
      padding: 0 !important;
      height: fit-content !important;
    }
  }
}

.mat-form-field {
  @include font($font-body);
  width: 100%;
  line-height: inherit;
  border: 1px solid $color-warm-lighter;
  border-radius: $border-radius;
  color: $color-black;
  background-color: transparent;

  &.mat-focused,
  &:focus-visible {
    border-color: $color-border-selected;
    outline: none;
  }

  &:hover {
    border-color: $color-border-input-hover;
  }

  &:active{
    &::placeholder {
      color: $color-black;
    }
  }

  &.mat-form-field-invalid.ng-touched {
    border-color: $color-alert;
    color: $color-alert;
  }

  &.mat-form-field-type-mat-select {
    box-sizing: border-box;
    line-height: initial;
  }

  &.mat-form-field-disabled {
    color: $color-dark-light;
    background-color: $color-grey-light;
    margin-top: -1px;
    padding-top: 1px;
  }

  .mat-select-disabled .mat-select-value {
    color: inherit;
  }

  .mat-select.mat-select-disabled .mat-select-arrow-wrapper .mat-select-arrow {
    &::before {
      color: $color-warm;
    }
  }

  .mat-form-field-wrapper {
    margin: 0;
    padding-bottom: 0;
  }

  .mat-form-field-infix {
    min-width: inherit;
    width: 100%;
    padding: 0;
    border: none;
  }

  .mat-form-field-underline,
  .mat-form-field-label-wrapper,
  .mat-form-field-subscript-wrapper {
    display: none;
  }

  .mat-input-element {
    caret-color: black !important;
  }

  ::ng-deep input {
    @include font($font-body);
    width: 100%;
    padding: 11px 13px;
    border: 1px solid $color-dark-light;
    border-radius: $border-radius;
    color: $color-dark;
    caret-color: black;
    font-family: $font-family-primary;
    transition: 0.2s all ease-in-out;

    &::placeholder {
      color: $color-black-lightest;
    }

    &:focus-visible {
      border-color: $color-warm;
      outline: none;
    }

    &:active{
      &::placeholder {
        color: $color-black;
      }
    }
  }
}

.mat-autocomplete-panel {
  .mat-option {
    height: unset;
    padding: 8px 16px;
    font-size: 12px;
    line-height: inherit;
    color: $color-dark;

    @include min-screen($desktop) {
      font-size: 14px;
    }
  }

  .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled):not(.mat-selected),
  .mat-option:hover:not(.mat-option-disabled):not(.mat-selected) {
    background: $color-brand-green-bright;
    color: $color-black-lighter;
  }

  .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled),
  .mat-option.mat-active,
  .mat-pseudo-checkbox-checked {
    background: $color-white;
    color: $color-black-lighter;
    font-weight: $font-weight-medium;
  }

  .mat-option:focus-visible:not(.mat-option-disabled),
  .mat-option:active:not(.mat-option-disabled) {
    background: $color-brand-green-bright;
    color: $color-black-lighter;
    font-weight: $font-weight-medium;
  }

  .mat-option-disabled {
    color: $color-dark-light;
    background-color: $color-grey-light;
  }
}

.mat-select {
  height: 42px;

  .mat-select-placeholder {
    @include font($font-body);
    color: $color-dark-light !important;
    -webkit-text-fill-color: $color-dark-light !important;
    line-height: 1 !important;
  }

  .mat-select-arrow-wrapper {
    display: block;

    .mat-select-arrow {
      border: 0;
      margin-right: 13px;
      font-weight: $font-weight-regular;

      &::before {
        font-family: $font-family-awesome;
        content: '\f078';
        color: $color-black-lightest;
      }
    }
  }

   &[aria-owns] {
    .mat-select-arrow {

      &::before {
        content: '\f077';
      }
    }
  }
}

.mat-select-panel {
  margin-top: 40px;
  padding: 8px;

  &.mat-select-panel--no-space {
    padding: 0;
  }

  .mat-option {
    position: relative;
    padding: 16px;
    height: auto !important;

    &:not(:first-of-type) {
      border-top: 1px solid $color-warm-lightest;
    }
  }
}

.mat-select-panel,
.mat-autocomplete-panel {
  @include scrollbar();

  .mat-option.mat-active {
    background: $color-white;
  }

  .mat-option.mat-selected.mat-active,
  .mat-option.mat-active,
  .mat-option.mat-selected,
  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    background: $color-background-selected-subtle;
    color: $color-text-primary;
    font-weight: $font-weight-medium;
  }

  .mat-option:not(.mat-active):hover,
  .mat-option:not(.mat-active):focus-visible {
    background: $color-brand-green-bright;
    color: $color-black-lighter;

    .mat-option-text {
      color: $color-black-lighter;
    }
  }

  .mat-option:hover,
  .mat-option:focus-visible {
    .mat-pseudo-checkbox-checked,
    .mat-pseudo-checkbox-indeterminate {
      background-color: $color-brand-green;
    }

    .mat-pseudo-checkbox {
      color: $color-brand-green;
    }
  }

  .mat-option:active {
    background: $color-brand-green-bright;
    color: $color-black-lighter;

    .mat-option-text {
      color: $color-text-primary;
    }

    .mat-primary .mat-pseudo-checkbox-checked,
    .mat-primary .mat-pseudo-checkbox-indeterminate {
      background-color: $color-black-light;
    }

    .mat-pseudo-checkbox {
      color: $color-black-light;
    }
  }

  .mat-option-text {
    @include font($font-body);
    color: $color-text-primary;
  }
}

.mat-form-field-hide-placeholder .mat-input-element::placeholder {
  display: block !important;
  color: $color-dark-light !important;
  -webkit-text-fill-color: $color-dark-light !important;
}

input.mat-form-field-autofill-control {
  padding-right: 55px;
}

.mat-checkbox,
.mat-radio-button {
  label {
    margin-bottom: 1px;
  }

  .mat-checkbox-frame,
  .mat-radio-outer-circle {
    border-width: 1px;
    border-color: $color-black;
  }

  .mat-checkbox-inner-container {
    width: 18px;
    height: 18px;

    &:hover,
    &:focus-visible {
      .mat-checkbox-frame {
        border-color: $color-brand-green;
      }

      .mat-checkbox-persistent-ripple,
      .mat-ripple-element {
        display: none;
      }
    }

    &:active {
      .mat-checkbox-frame {
        border-color: $color-black-light;
      }

      .mat-checkbox-persistent-ripple,
      .mat-ripple-element {
        display: none;
      }
    }
  }
}

.mat-radio-button {
  margin: 0 !important;

  .mat-radio-group & {
    margin: 16px 16px 0 !important;
  }

  .mat-radio-outer-circle,
  .mat-radio-inner-circle,
  .mat-radio-container {
    width: 15px;
    height: 15px;
  }

  .mat-radio-inner-circle {
    transform: scale(0);
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(0.5);
  }

  .mat-radio-ripple {
    opacity: 0 !important;
  }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $color-black-lighter;

  &:hover,
  &:focus-visible {
    background-color: $color-brand-green;
  }

  &:active {
    background-color: $color-black-light;
  }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $color-black;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $color-black;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  display: none;
}

.mat-pseudo-checkbox {
  color: $color-black-lighter;
}

.mat-checkbox-indeterminate.mat-accent,
.mat-checkbox-checked.mat-accent {
  .mat-checkbox-background {
    background-color: $color-black-lighter;
  }

  &:hover,
  &:focus-visible {
    .mat-checkbox-background {
      background-color: $color-brand-green;
    }
  }

  &:active {
    .mat-checkbox-background {
      background-color: $color-black-light;
    }
  }
}

.mat-checkbox-disabled {
  pointer-events: none;
}

.mat-checkbox-indeterminate.mat-accent.mat-checkbox-disabled .mat-checkbox-background,
.mat-checkbox-checked.mat-accent.mat-checkbox-disabled .mat-checkbox-background {
  background-color: $color-black-bright;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: $color-black-bright;
}

.mat-checkbox--rounded {
  .mat-checkbox-frame,
  .mat-checkbox-background {
    border-radius: 50%;
  }

  .mat-checkbox-background {
    width: 8px;
    height: 8px;
    margin: auto;
  }

  .mat-checkbox-checkmark {
    display: none;
  }
}

.mat-autocomplete-panel-above {
  margin-bottom: 0;
}

.mat-select-panel-wrap {
  padding: 0 8px;
  margin-top: -5px;
}

.cdk-overlay-pane,
.mat-autocomplete-panel {
  @include scrollbar();
}

.cdk-column-select {
  max-width: 18px;
  padding-right: 0;
}

.mat-optgroup .mat-optgroup-label {
  height: inherit;
  margin: 5px 0;
  padding: 8px 12px;
  background: $color-grey-lighter;
  line-height: inherit;
  font-weight: $font-weight-medium;
}

// Radio button
.mat-radio-label {
  margin: 0;
}

.mat-radio-button {
  margin: 16px;
}

.mat-radio-group {
  margin: -16px;
}

// Phone input
.intl-tel-input {
  @include scrollbar();

  .selected-flag {
    outline: 0;
  }

  .country-list .country {
    @include font($font-body);
    max-width: 442px;
    padding: 6px 12px;
    font-weight: $font-weight-regular;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .country-list .flag-box,
  .country-list .country-name {
    margin-right: 12px;
  }
}

.intl-tel-input.allow-dropdown .selected-flag,
.intl-tel-input.separate-dial-code .selected-flag {
  width: 55px;
  padding: 0 0 0 12px;
}

.intl-tel-input .selected-flag .iti-arrow {
  right: 8px;
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type=text],
.intl-tel-input.allow-dropdown input[type=tel],
.intl-tel-input.separate-dial-code input,
.intl-tel-input.separate-dial-code input[type=text],
.intl-tel-input.separate-dial-code input[type=tel] {
  padding: 6px 12px 3px 60px;
}

.iti--allow-dropdown input[type="tel"] {
  padding-left: 84px !important;
}

.iti__selected-flag {
  padding: 0 24px 0 12px !important;

  body.rtl & {
    padding: 0 12px 0 24px !important;
  }
}

.intl-tel-input .country-list,
.intl-tel-input .flag-container {
  width: 100%;
}

.uni-form-field.is-disabled {
  .iti__flag-container:hover,
  .iti__flag-container:hover .iti__selected-flag {
    background-color: inherit;
    cursor: auto;
    outline: 0;
  }
}

.iti__flag-container {
  border-right: 2px solid $color-warm-lighter;

  .is-disabled & {
    border-right: 2px solid $color-warm;
  }

  body.rtl & {
    border-right: none;
    border-left: 2px solid $color-warm-lighter;

    .is-disabled & {
      border-right: none;
      border-left: 2px solid $color-warm;
    }
  }
}

.iti__arrow {
  display: inline-block;
  height: 12px;
  margin-left: 10px;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: $font-weight-regular;
  color: $color-warm;
  font-family: $font-family-awesome;
  border: none;

  &::before {
    content: "\f078";
  }
}

.iti__arrow.iti__arrow--up {
  &::before {
    content: "\f077";
  }
}

// Angular Tree Component https://www.npmjs.com/package/angular-tree-component

.angular-tree-component {
  font-weight: $font-weight-medium;

  .tree-node-level-2,
  .tree-node-level-3 {
    text-transform: initial;
  }

  .tree-node-level-3 .node-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: none;
    font-weight: $font-weight-regular;
  }

  .tree-node-level-2.tree-node-expanded .node-wrapper:first-of-type {
    border-bottom: none;
  }

  .tree-node {
    cursor: auto;
  }

  .node-wrapper {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $color-dark-lighter;

    .node-content-wrapper-active,
    .node-content-wrapper-focused,
    .node-content-wrapper:hover {
      background: transparent;
      box-shadow: none;
    }

    tree-node-expander {
      margin-left: auto;
      order: 3;
      cursor: pointer;

      .toggle-children-wrapper-expanded .toggle-children {
        transform: rotate(180deg);
      }

      .toggle-children {
        height: unset;
        width: unset;
        background-image: none;

        &:before {
          display: block;
          content: "\f078";
          font-size: 12px;
          font-family: $font-family-awesome;
          font-weight: $font-weight-medium;
          color: $color-active;
        }
      }
    }
  }
}

.mat-slider {
  .mat-slider-track-background {
    background-color: $color-white!important;
  }

  .mat-slider-track-wrapper,
  .mat-slider-track-fill {
    height: 4px!important;
    cursor: pointer;
  }

  .mat-slider-thumb {
    background-color: $color-active!important;
    border-color: $color-active!important;
  }
}

.mat-slider-horizontal {
  height: 32px!important;

  .mat-slider-wrapper {
    top: 15px!important;
  }
}


// Material tooltips

.mat-tooltip {
  box-sizing: content-box;
}

.mat-tooltip--info {
  position: relative;
  background-color: $color-active-lighter;
  box-shadow: $shadow-wide;
  font-weight: $font-weight-medium;
  font-size: 12px;
  letter-spacing: 0.07em;
  line-height: 20px;
  color: $color-dark !important;
  overflow: visible !important;

  &::after {
    content: '';
    position: absolute;
    right: calc(50% - 10px);
    top: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: $color-active-lighter transparent  transparent transparent;
  }
}

.mat-tooltip-trigger {
  color: $color-brand-green !important;
}

.cdk-overlay-container {
  z-index: 1999;
}
