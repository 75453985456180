body.rtl {
  text-align: initial;
}

.uni-modal__content {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.uni-section--default {
  margin: 16px 0 !important;

  &.is-first {
    margin-top: 0 !important;
  }

  &.is-last {
    margin-bottom: 0 !important;
  }
}

.uni-form-field--margin {
  margin-bottom: 16px !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.mat-ink-bar {
  transition: 100ms cubic-bezier(0.35, 0, 0.25, 1) !important;
}

.mat-menu-panel *[class*="uni-button"] {
  height: inherit;
}
