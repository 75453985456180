@keyframes animation-pulse {
  0% {
    border-color: $color-dark-lighter;
    background-color: $color-grey-light;
  }
  50% {
    background-color: $color-active-lighter;
    border-color: $color-active-light;
  }
  100% {
    border-color: $color-dark-lighter;
    background-color: $color-grey-light;
  }
}

.uni-input,
input.mat-input,
input.uni-input {
  width: 100%;
  height: $input-height;
  padding: 14px;
  border: 1px solid $color-warm-lighter;
  border-radius: $border-radius;
  color: $color-black;
  background-color: $color-background-input-default;
  font-size: 12px;
  font-family: $font-family-primary;
  line-height: initial;
  transition: 0.2s color ease-in-out;
  box-sizing: border-box;

  @include min-screen($desktop) {
    font-size: 14px;
  }

  &::placeholder {
    color: $color-text-placeholder;
    font-size: 14px;
    font-family: $font-family-primary;
    line-height: initial;
  }

  &:not(.mat-form-field) {
    padding: 12px 12px 14px;
  }

  @include min-screen($desktop) {
    font-size: 14px;
  }

  &:hover {
    background-color: $color-background-input-default-hover;
    border-color: $color-border-input-hover;
    &:not(.mat-select.uni-input):not(.mat-form-field .uni-input) {
      background-color: $color-background-input-default-hover;
    }
    border: 1px solid $color-border-input-hover;
  }

  &:disabled {
    color: $color-text-disabled;
    background-color: $color-background-input-disabled;
    border-color: $color-border-input-disabled;
    pointer-events: none;
  }

  &:active{
    border-color: $color-border-selected;
    color: $color-text-primary;
  }

  &:focus-visible {
    border-color: $color-border-selected;
    outline: $color-border-selected;
  }

  &.is-highlighted {
    color: $color-dark;
    animation: 2s animation-pulse infinite;
  }

  .mat-form-field & {
    border: none;
  }

  .uni-datepicker & {
    @include rtl {
      direction: ltr;
      text-align: right;
    }
  }
}

input.mat-input-element {
  margin-top: 0 !important;
}

textarea.uni-input {
  min-height: 140px;

  &:read-only {
    color: $color-black-lightest;
    background-color: $color-black-brighter;
    pointer-events: none;
  }
}

.uni-input--error,
.uni-input--error:focus-visible,
input.uni-input--error,
input.uni-input--error:focus-visible {
  border-color: $color-error-lighter;
}
