@mixin rtl {
  .rtl &,
  .rtl :host & {
    @content;
  }
}

@mixin ltr {
  .ltr &,
  .ltr :host & {
    @content;
  }
}

@mixin fullfill() {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

@mixin scrollbar() {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-grey-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-dark-light;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($color-dark-light, 5%);
  }
}

@mixin scrollbar-fine() {
  &::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-white;
    border: 1px solid $color-grey-lighter;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-active-light;
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($color-active-light, 5%);
  }
}

// Breakpoints
// ------------------------------------------
@mixin min-screen($resolution) {
  @media screen and (min-width: $resolution) {
    @content;
  }
}

@mixin max-screen($resolution) {
  @media screen and (max-width: $resolution - 1) {
    @content;
  }
}

@mixin min-max-screen($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max - 1) {
    @content;
  }
}

// Alignment
// ------------------------------------------
@mixin align-h($position: absolute) {
  transform: translateX(-50%);
  position: #{$position};
  left: 50%;
}

@mixin align-v($position: absolute) {
  transform: translateY(-50%);
  position: #{$position};
  top: 50%;
}

@mixin align-vh($position: absolute) {
  transform: translate(-50%, -50%);
  position: #{$position};
  top: 50%;
  left: 50%;
}

// Interpolations
// ------------------------------------------
@function linear-interpolation($map) {
  $keys: map-keys($map);

  @if (length($keys) != 2) {
    @error 'linear-interpolation() $map must be exactly 2 values';
  }

  $m: (map-get($map, nth($keys, 2)) - map-get($map, nth($keys, 1))) / (nth($keys, 2) - nth($keys, 1));
  $b: map-get($map, nth($keys, 1)) - $m * nth($keys, 1);
  $sign: '+';

  @if ($b < 0) {
    $sign: '-';
    $b: abs($b);
  }

  @return calc(#{$m*100}vw #{$sign} #{$b});
}

@mixin interpolation($style, $map) {
  $keys: map-keys($map);
  #{$style}: linear-interpolation($map);

  @include max-screen(nth($keys, 1)) {
    #{$style}: map-get($map, nth($keys, 1));
  }

  @include min-screen(nth($keys, 2)) {
    #{$style}: map-get($map, nth($keys, 2));
  }
}

@mixin interpolation-multi($styles, $map) {
  $keys: map-keys($map);

  @each $name in $styles {
    #{$name}: linear-interpolation($map);
  }

  @include max-screen(nth($keys, 1)) {
    @each $name in $styles {
      #{$name}: map-get($map, nth($keys, 1));
    }
  }

  @include min-screen(nth($keys, 2)) {
    @each $name in $styles {
      #{$name}: map-get($map, nth($keys, 2));
    }
  }
}

@mixin center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
